// PublicationsPage.js
import React from 'react';

const PublicationsPage = () => (
    <div className="content">
            <div className="hero-img" >Image goes here </div>
        <h1>Publications</h1>

    </div>
);

export default PublicationsPage;
