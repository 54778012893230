// ServicesPage.js
import React from 'react';

const ServicesPage = () => (
    <div className={"content services"}>
            <div className="hero-img" >Image goes here </div>
        <h1>Services</h1>

        <p>Monté CrisToph Music Offers Custom Music for Cutting-Edge Companies</p>
        <p>Original, unique custom music accompaniment not only enhances your business pitch or presentation – It does so much more!</p>

        <p>Allow us to provide your company with custom music that will give your product or service the voice it needs.</p>


        <p>Branding Companies – This particular type of company has proven to be an essential asset in today’s savvy business world. The branding company acts as the “expert agent” of sorts by helping emerging as well as existing companies establish a substantial market presence from the name to the symbol and even the design that differentiates a product from other products. We customize music for the branding company to enhance the presentation for most any product or service to attain mainstream appeal.</p>


        <p>Gaming Companies – The Video Gaming industry has taken unprecedented leaps sense the days of jumping on blocks and collecting coins. The presentation, look and overall immersion of this now multi-billion-dollar industry have changed all about how we can dive into a virtual world. Our music will elevate the experience that your game needs to bring the player even closer to the world you created.</p>
        <p>IT Companies – Information Technology (IT) is the full process in which a computer receives, interprets, structures and sends any form of data that is digitally created. It is a digital brain that constantly learns new data that is received and then translates it back to recipients who would want or need this data the most. The digital world is algorithmically built on prioritizing the necessity of stellar and efficient presentation. We offer the IT world music that enhances fine-tuned and fantastic results.</p>
        <p>Virtual Reality Companies – It is the job of the virtual reality company to create for a participant an artificial environment through sensory stimuli (i.e., imagery, sounds and music), so they may interact with and partially determine what occurs in the environment. A state of the art experience such as virtual reality demands cutting age sounds and/or musical elements to elevate the sensation of this unique experience. We will provide your virtual reality company with the sound it needs.</p>
        <p>Artificial Intelligence Companies – Artificial Intelligence was once a science fiction promise to a new world, yet it is now an oncoming happening of the present. As unknown as it is wondrous, we as a people, are still wanting to mentally grapple with the idea of an intelligent form of being, outside of ourselves. Our music can cater to the ease, excitement and fascination that your company provides in its projects that showcase intelligence in a new light and life.</p>


   </div>
);

export default ServicesPage;
