// App.js
import React from 'react';
import HomePage from "./HomePage";
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import PrivacyPage from "./PrivacyPage";
import AboutPage from "./AboutPage"
import BlogPage from "./BlogPage"
import Footer from "./Footer"
import Navbar from "./Navbar"
import ContactUsPage from "./ContactUsPage";
import NotFoundPage from "./NotFoundPage";
import DynamicBlogPost from "./DynamicBlogPost";
import ServicesPage from "./ServicesPage";
import ShopPage from "./ShopPage";
import PublicationsPage from "./PublicationsPage";

function App() {
    return (
       <Router>
           <div className={"App"}>
               <Navbar />
               <Routes>
                   <Route path={"/"} element={<HomePage />} />
                   <Route path={"about-montecristoph-music"} element={<AboutPage />} />
                   <Route path={"privacy-policy"} element={<PrivacyPage />} />
                   <Route path={"contact-us"} element={<ContactUsPage />} />
                   <Route path={"404.html"} element={<NotFoundPage />} />
                   <Route path="/blog/*" element={<BlogPage />} />
                   <Route path="shop" element={<ShopPage />} />
                   <Route path="services" element={<ServicesPage />} />
                   <Route path="publications" element={<PublicationsPage />} />
                   <Route path="/blog/:id" element={<DynamicBlogPost />} />
                   <Route path="/blog/post/:urlkey" element={<DynamicBlogPost />} />

               </Routes>
           </div>
           <Footer />
       </Router>
    );
}

export default App;
