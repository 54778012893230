// HeroSection.js
import React from 'react';

const HeroSection1 = () => (
    <div>
        <div className="content">
            <h1>Monté CrisToph Music</h1>
            <p></p>
        </div>
    </div>
        );
export default HeroSection1;