// ServicesPage.js
import React from 'react';

const ShopPage = () => (
    <div className="content">
            <div className="hero-img" >Image goes here </div>
        <h1>Shop</h1>
                      <p>&nbsp;</p>
         </div>
);

export default ShopPage;
