// Footer.js
import React from 'react';
import SocialLinks from './SocialLinksSection';


const Footer = () => (
    <section className="footer">
        <div><SocialLinks />
        </div>
        <div className={"copyright"} >Monté CrisToph Multimedia © 2024 All Rights Reserved</div>

    </section>
);

export default Footer;


