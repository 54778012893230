// AboutPage.js
import React from 'react';
import hero from './img/Lord_Toph-Star_Man-transparent.png'

const AboutPage = () => (
    <div className="content contact">
            <div className="hero img" ><img src={hero} alt={"Lord Toph"} /></div>
        <h1 className={'extra'}>About Monté CrisToph Music</h1>

          </div>
);


export default AboutPage;