// ContactUsPage.js
import React from 'react';

const ContactUsPage = () => (
    <div className={"content"}>

            <div className="hero-img" >Image goes here </div>
            <h1>Contact Monté Cristoph Music</h1>
        <div className={"contact"}> <p>Music is about Connectivity</p>
        <p>So feel free to contact us!</p>
        <p>We want our music to resonate with you. Let us know! We would love to hear from you.</p>
        <p>The best growth for any great idea starts with communication! Most great moments throughout history were formed from more than one place or person. However anything substantial originates, all great things are spread by communicating with like-minded individuals.</p>

        <p>Connect with Monté CrisToph Music if you would like to share your interests, thoughts and ideas. And if you just want to connect with us to share your support and follow our future music projects, it’s just as valuable! Connecting with new listeners is the very reason we do what we do.</p>

        <p>Should you have inquiries about: artist bookings, music rights for events, projects or products, commission rates for a new composition, production collaborations, promoting our artists, label, music or products… Or if you would simply like to drop us a friendly note, then don’t hesitate to contact us by using the information provided below.</p>

        <p>Cheers!</p>
        <p>–Monté CrisToph Music</p>

        <h2><a href="mailto:montecristophmusic@gmail.com">Send Email to montecristophmusic@gmail.com</a></h2>
         </div></div>
        );

export default ContactUsPage;